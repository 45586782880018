*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.header{
    text-align: center;
    padding: 25px 0;
    padding-bottom: 20px;
    border-bottom: 1px solid #f1f1f1;
    background-color: #fff;
    width: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99;
}
.header h1{
    font-size: 30px;
    margin: 0 0 15px;
}
.header input{
    width: 400px;
    padding: 15px 20px;
    font-size: 18px;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    color: #333;
    text-align: center;
}
.header input:focus{
    outline: none;
    border: 1px solid orange;
}
.icons-wrap{
    max-width: 90%;
    margin: 0 auto;
    padding-top: 180px;
}
.icons-list{
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 15px;
}
.icons-list li{
    display: block;
    text-align: center;
    font-size: 14px;
    border: 1px solid #f1f1f1;
    padding: 5px;
}
.icons-list li i{
    display: block;
    font-size: 100px;
    color: #313131;
    margin-bottom: 4px;
}
.icons-list li button{
    display: block;
    border: none;
    box-shadow: none;
    background: #eee;
    width: 100%;
    margin-top: 10px;
    padding: 5px 10px;
    cursor: pointer;
}
.icons-list li button:focus{
    border: 1px solid green;
}
@media (max-width:992px){
    .icons-list{
        grid-template-columns: repeat(5, 1fr);
    }
}

@media (max-width:767px){
    .icons-list{
        grid-template-columns: repeat(3, 1fr);
    }
}
